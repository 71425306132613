<template>
  <div class="home">
    <van-tabs
      color="#577DF8"
      title-active-color="#577DF8"
      :line-width="12"
    >
      <van-tab title="客户简况">
        <bind-client v-if="!isBind" />
        <intro
          v-else
          :evaluation-data="form"
        />
      </van-tab>
      <van-tab title="跟进详情">
        <bind-client v-if="!isBind" />
        <follow-detail
          v-else
          :evaluation-data="form"
          @refresh="getEvaluation"
        />
      </van-tab>
      <!-- <van-tab title="快捷回复">
        <quick-reply />
      </van-tab>
      <van-tab title="快捷文件">
        <quick-file />
      </van-tab> -->
      <van-tab title="聊天记录">
        <chat-record />
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */

import { Tab, Tabs, Toast, } from 'vant';
import FollowUpDetails from './FollowUpDetails.vue';
import QuickReply from './QuickReply.vue';
import QuickFile from './QuickFile.vue';
import { getLoginCode, getSignature, } from '@/script/wxWork';
import BindClient from './BindClient';
import Utils from '@/script/utils';
import { crmLogin, } from '@/request/api/common';
import store from '@/store/index';
import ChatRecord from './ChatRecord.vue';
import Intro from './Intro';
import FollowDetail from './FollowDetail/index.vue';
import { get, } from 'idb-keyval';
import { getEvaluation, } from '@/request/api/followUpDetails';

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Home',
  components: {
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    FollowDetail,
    BindClient,
    ChatRecord,
    Intro,
  },
  data() {
    return {
      form: {
        evaluation: undefined,
        intentionLevel: undefined,
        invalidTypeId: undefined,
      },
    };
  },
  computed: {
    isBind: function() {
      if (store.state.isBind) {
        this.getEvaluation();
      }
      return store.state.isBind;
    },
  },
  created() {
    const { code, } = this.$route.query;
    if (!code && !Utils.getCookie('Authorization',)) {
      getLoginCode();
    }
    if (code && !Utils.getCookie('Authorization',)) {
      if (Utils.getStore('wxCode',) === code) {
        getLoginCode();
      } else {
        Toast.loading({
          duration: 0, // 持续展示 toast
          forbidClick: true,
          message: '正在验证登陆中。。。',
        },);
        crmLogin(code,).then((res,) => {
          Utils.setCookie('Authorization', res.token,);
          Utils.setCookie('wxUserId', res.wxUserId,);
          Utils.setCookie('userId', res.userId,);
          setTimeout(() => {
            getSignature();
            Toast.clear();
          }, 2000,);
        },);
      }
      Utils.setStore('wxCode', code,);
    }
    if (Utils.getCookie('Authorization',)) {
      getSignature();
    }
  },
  methods: {
    async getEvaluation() {
      // 在这里调，是因为报名按钮需要有效性判断
      const { form, } = this;
      const res2 = await getEvaluation(Utils.getStore('cardId',),);
      const { evaluation, intentionLevel, invalidType, } = res2;
      form.evaluation = evaluation;
      form.intentionLevel = intentionLevel;
      form.invalidTypeId = invalidType ? invalidType.id : undefined;
    },
  },
};
</script>

<style lang="less">
.home {
  height: 100%;
  .van-tabs {
    height: 100%;
  }
  .van-tabs__{
    &wrap {
      width: 100%;
      position: fixed;
      left: 0;
    }
    &content {
      height: 100%;
      padding: 44px 0;
      .van-tab__pane {
        height: 100%;
      }
    }
  }
}
</style>
