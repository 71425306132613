/* eslint-disable no-undef */
import { Toast, } from 'vant';
import { getTicket, getSha1, } from '@/request/api/common';
import { randomString, } from '@/script/var';
import Utils from '@/script/utils';
// import { set, get, } from 'idb-keyval';
import store from '@/store/index';

const Corp_Id = 'wwba633c63f701b9ad';
const Agent_Id = process.env.VUE_APP_Agent_Id;
const is_Debug = process.env.NODE_ENV === 'development';
const Redirect_Uri = process.env.VUE_APP_URL;

export const getSignature = () => {
  getTicket().then((res,) => {
    const { appTicket, corpTicket, } = res;
    setCofig(appTicket,).then(() => {
      setAgentConfig(corpTicket,).then(() => {
        wx.invoke('getCurExternalContact', {
        }, function(res,) {
          const { err_msg, userId, } = res; // 返回当前外部联系人userId
          if (err_msg === 'getCurExternalContact:ok') {
            Utils.setLocalstorageStore('externalId', userId,);
            // v7.4改成只要外部联系人跟名片绑定了，大家添加同一个人都能看到名片
            store.dispatch('getIsBind',);
            // get(userId,).then((val,) => {
            //   if (!val) {
            //     set(userId, 0,).then(() => { // 键为外部联系人，值为cardId，未绑定为0
            //       console.log('存储外部联系人成功，但还未绑定',);
            //       Toast.success('存储外部联系人成功，但还未绑定',);
            //     },).catch((err,) => {
            //       console.error(err, '存储外部联系人失败',);
            //       Toast.fail('存储外部联系人失败',);
            //     },);
            //   } else {
            //     store.dispatch('bindSuccess',).then(() => {
            //       Toast('客户已绑定',);
            //     },);
            //   }
            // },);
          } else {
            // 错误处理
            console.error('获取外部联系人失败',);
            Toast.fail('获取外部联系人失败',);
          }
        },);
      },);
    },).catch((e,) => {
      console.error(e,);
    },);
  },);
};

function setCofig(params,) {
  const data = {
    jsapi_ticket: params,
    timestamp: getTimestamp(),
    noncestr: randomString(10,),
    url: location.href,
  };
  return new Promise((resolve, reject,) => {
    getSha1(data,).then((signature,) => {
      wx.config({
        beta: true, // 必须这么写，否则wx.invoke调用形式的jsapi会有问题
        debug: is_Debug, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: Corp_Id, // 必填，企业微信的corpID
        timestamp: data.timestamp, // 必填，生成签名的时间戳
        nonceStr: data.noncestr, // 必填，生成签名的随机串
        signature, // 必填，签名，见 附录-JS-SDK使用权限签名算法
        jsApiList: [
          'checkJsApi',
          'agentConfig',
          'getCurExternalContact',
          'invoke',
          'openDefaultBrowser',
        ], // 必填，需要使用的JS接口列表，凡是要调用的接口都需要传进来
      },);
      wx.ready(() => {
        // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，
        // config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，
        // 则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。
        resolve(null,);
      },);
      wx.error((res,) => {
        // getSignature();
        reject(res,);
      },);
    },);
  },);
}

// config注入的是企业的身份与权限，而agentConfig注入的是应用的身份与权限
function setAgentConfig(params,) {
  const data = {
    jsapi_ticket: params,
    timestamp: getTimestamp(),
    noncestr: randomString(10,),
    url: location.href,
  };
  return new Promise((resolve, reject,) => {
    getSha1(data,).then((signature,) => {
      wx.agentConfig({
        corpid: Corp_Id, // 必填，企业微信的corpid，必须与当前登录的企业一致
        agentid: Agent_Id, // 必填，企业微信的应用id （e.g. 1000247）
        timestamp: data.timestamp, // 必填，生成签名的时间戳
        nonceStr: data.noncestr, // 必填，生成签名的随机串
        signature, // 必填，签名，见附录-JS-SDK使用权限签名算法
        jsApiList: [
          'getContext',
          'getCurExternalContact',
          'invoke',
          'sendChatMessage',
          'openDefaultBrowser',
        ], // 必填，传入需要使用的接口名称
        success: function(res,) {
          // 回调
          resolve(res,);
        },
        fail: function(res,) {
          if (res.errMsg.indexOf('function not exist',) > -1) {
            Toast.fail('版本过低请升级',);
          }
          reject(res,);
        },
      },);
    },);
  },);
}

const getTimestamp = () => {
  return Math.round(new Date() / 1000,);
};

/**
 * 通用调用企业微信 SDK 的函数
 * @param apiName api 名称
 * @param params 传入参数
 */
// eslint-disable-next-line no-unused-vars
function invoke(apiName, params = {},) {
  return new Promise((resolve,) => {
    wx.invoke((apiName, params, res,) => {
      console.log(apiName, res,);
      const hasError = res.err_msg !== `${apiName}:ok`;

      if (hasError) {
        // 错误日志
        console.error(apiName, params, res,);
      }
      console.log(res,);
      resolve({ ...res, hasError, },);
    },);
  },);
}

export const getLoginCode = () => {
  const url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${Corp_Id}&redirect_uri=${Redirect_Uri}&response_type=code&scope=snsapi_base&state=#wechat_redirect
    `;
  const link = document.createElement('a',);
  link.style.display = 'none';
  link.href = url;
  link.click();
};

export function quickReply(content,) {
  return new Promise((resolve,) => {
    wx.invoke('sendChatMessage', {
      msgtype: 'text', // 消息类型，必填
      enterChat: true, // 为true时表示发送完成之后顺便进入会话，仅移动端3.1.10及以上版本支持该字段
      text: {
        content, // 文本内容
      },
    }, function(res,) {
      if (res.err_msg === 'sendChatMessage:ok') {
        // 发送成功
        resolve(null,);
      }
    },);
  },);
}

export function quickFile(mediaid,) {
  return new Promise((resolve,) => {
    wx.invoke('sendChatMessage', {
      msgtype: 'file', // 消息类型，必填
      enterChat: true, // 为true时表示发送完成之后顺便进入会话，仅移动端3.1.10及以上版本支持该字段
      file: {
        mediaid, // 文本内容
      },
    }, function(res,) {
      if (res.err_msg === 'sendChatMessage:ok') {
        // 发送成功
        resolve(null,);
      }
    },);
  },);
}

// 在默认浏览器打开
export function openDefaultBrowser(url,) {
  wx.invoke('openDefaultBrowser', {
    url,
    // url: `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${Corp_Id}&redirect_uri=${url}&response_type=code&scope=SCOPE&agentid=${Agent_Id}&state=STATE#wechat_redirect`, // 在默认浏览器打开redirect_uri，并附加code参数；也可以直接指定要打开的url，此时不会附带上code参数。
  }, function(res,) {
    if (res.err_msg === 'openDefaultBrowser:ok') {
      Toast.success('已使用默认浏览器打开CRM',);
    } else {
      // 错误处理
      Toast.fail('打开默认浏览器失败',);
      window.open(url, '_blank',);
    }
  },);
}
