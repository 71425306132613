<template>
  <div class="bind-client">
    <div class="search">
      <van-search
        v-model="search"
        show-action
        clear-trigger="always"
        placeholder="请输入学员的手机/微信号/QQ"
        left-icon=""
        :error="isError"
        @search="onSearch"
      >
        <template #action>
          <div @click="onSearch()">搜索</div>
        </template>
      </van-search>
    </div>
    <div
      v-show="list.length"
      class="clients"
    >
      <client-com
        v-for="item in list"
        :key="item.customerId"
        :info="item"
      />
    </div>
    <van-empty
      v-show="!list.length"
      :image="require('@/assets/not_bind.svg')"
      :image-size="229"
      description="此客户尚未关联CRM客户 请先进行关联"
    />
  </div>
</template>

<script>
import { Empty, Button, Dialog, Search, Toast, } from 'vant';
import 'ant-design-vue/lib/badge/style';
import { phoneEx, } from '@/script/var';
import { getCard, } from '@/request/api/common';
import ClientCom from './clientCom.vue';

export default {
  name: 'BindClient',
  components: {
    [Empty.name]: Empty,
    [Button.name]: Button,
    [Dialog.Component.name]: Dialog.Component,
    [Search.name]: Search,
    ClientCom,
  },
  data() {
    return {
      show: false,
      search: '',
      isError: false,
      list: [],
    };
  },
  methods: {
    handleBind() {
      this.show = true;
    },
    onSearch(val,) {
      const { search, getClient, } = this;
      const value = val || search;
      if (value.trim() && value.trim().length === 11) {
        const isValidPhone = phoneEx.test(value.trim(),);
        if (!isValidPhone) {
          this.isError = true;
          Toast.fail('请输入正确的手机号',);
        } else {
          getClient(value.trim(),);
        }
      } else if (value.trim()) {
        getClient(value.trim(),);
      } else if (!value.trim()) {
        Toast.fail('联系方式不能全为空~',);
      }
    },
    async getClient(value,) {
      const res = await getCard(value,);
      if (res.rows.length) {
        this.list = res.rows;
      } else {
        Toast.fail('暂无此客户信息',);
      }
    },
  },
};
</script>

<style lang="less">
@import '../../style/common.less';

.bind-client {
  height: 100%;
  display: flex;
  flex-direction: column;
  .search {
    padding: 25px 15px 20px;
    background-color: #FFFFFF;
    .van-search {
      padding: unset;
      background-color: #F7F7F7;
      border: 1px solid rgba(0,0,0,0.15);
      border-radius: 4px;
      &__action {
        height: 26px;
        line-height: 26px;
        margin: 4px 7px;
        color: #FFFFFF;
        background-color: @theme;
        border-radius: 4px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
      }
    }
    .van-empty {
      flex: 1;
      padding: 0 0 44px;
    }
  }
	.clients {
    padding: 10px 15px;
  }
}
</style>
