<template>
  <van-popup
    :value="isPopup"
    position="bottom"
    round
    class="new-intention-com"
    @click-overlay="handleClickOverlay"
  >
    <h3>新增意向</h3>
    <van-form
      :show-error-message="false"
      @submit="handleSubmit"
    >
      <van-field
        :value="popup.name"
        name="name"
        label="姓名："
        readonly
      />
      <van-field
        :value="popup.phone"
        name="phone"
        label="手机："
        readonly
      />
      <van-field
        :value="popup.weChat"
        name="weChat"
        label="微信："
        readonly
      />
      <van-field
        v-model="priceAndTime.price"
        :rules="[{ required: true, message: '请填写意向报名价格' }]"
        name="price"
        type="number"
        label="意向报名价格"
        placeholder="请填写"
        class="price-and-time"
      >
        <template #button>
          元
        </template>
      </van-field>
      <van-field
        v-model="priceAndTime.time"
        :rules="[{ required: true, message: '请选择意向报名时间' }]"
        name="time"
        label="意向报名时间"
        placeholder="请选择"
        right-icon="notes-o"
        readonly
        clickable
        class="price-and-time"
        @click="handleTime"
      />
      <van-popup
        v-model="timeVisible"
        position="bottom"
      >
        <van-datetime-picker
          title="意向报名时间"
          :min-date="new Date()"
          @confirm="onConfirm"
          @cancel="handleTime"
        />
      </van-popup>
      <div class="btns">
        <van-button
          plain
          color="#577DF8"
          class="btn"
          @click="handleClickOverlay"
        >取消</van-button>
        <van-button
          :loading="loading"
          color="#577DF8"
          native-type="submit"
          class="btn"
        >提交</van-button>
      </div>
    </van-form>
  </van-popup>
</template>

<script>
import moment from 'moment';
import { Popup, Form, Field, DatetimePicker, Button, Toast, } from 'vant';
import { addIntentions, } from '@/request/api/customerProfile';

export default {
  name: 'NewIntentionCom',
  components: {
    [Popup.name]: Popup,
    [Form.name]: Form,
    [Field.name]: Field,
    [DatetimePicker.name]: DatetimePicker,
    [Button.name]: Button,
  },
  props: {
    isPopup: {
      type: Boolean,
      required: true,
    },
    popup: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      priceAndTime: {
        price: undefined,
        time: undefined,
      },
      timeVisible: false,
      loading: false,
    };
  },
  methods: {
    handleClickOverlay() {
      this.$emit('close',);
    },
    handleTime() {
      this.timeVisible = !this.timeVisible;
    },
    onConfirm(val,) {
      const { priceAndTime, handleTime, } = this;
      priceAndTime.time = moment(val,).format('YYYY-MM-DD HH:mm:SS',);
      handleTime();
    },
    async handleSubmit(values,) {
      const { popup, handleLoading, priceAndTime, handleClickOverlay, } = this;
      handleLoading();
      const data = {
        customerId: popup.id,
        intentionAmount: values.price,
        intentionTime: values.time,
      };
      const res = await addIntentions(data,).finally(() => handleLoading(),);
      if (res.status === 201) {
        Toast.success('新增意向成功',);
        priceAndTime.price = undefined;
        priceAndTime.time = undefined;
        handleClickOverlay();
        this.$emit('refresh',);
      }
    },
    handleLoading() {
      this.loading = !this.loading;
    },
  },
};
</script>

<style lang="less">
.new-intention-com {
  padding: 20px 25px 49px;
  border-radius: 6px 6px 0px 0px;
  h3 {
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    color: #333333;
    text-align: center;
  }
  .van-field__label {
    width: unset;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
  }
  .van-cell::after {
    border-bottom: unset;
  }
  .price-and-time {
    display: block;
    .van-field__label {
      font-weight: bold;
      margin-bottom: 8px;
    }
    .van-field__value {
      padding: 7px 10px;
      border: 1px solid rgba(0,0,0,0.1);
      border-radius: 2px;
    }
  }
  .btns {
    padding: 10px 16px;
    display: flex;
    justify-content: space-evenly;
    .btn {
      width: 156px;
      height: 38px;
      border-radius: 4px;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
    }
    .cancel-btn {

    }
  }
}
</style>
