/* eslint-disable no-array-constructor */
// 手机号 正则表达式
export const phoneEx = /^1(3[0-9]|4[57]|5[0-35-9]|6[0-9]|7[0-9]|8[0-9]|9[0-9])\d{8}$/;

// 身份证号
export const idCardEx = /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;

// 邮箱
export const emailEx = /^\w+((-\w+)|(\.\w+))*\u0040[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/;

// qq
export const qqEx = /^[1-9]\d{4,9}$/;

// 银行卡号
export const bankCardEx = /^([1-9]\d{14,18})$/;

// 图片
export const imageEx = /^.+\.(jpg|jpeg|png|gif|GIF|JPG|PNG|JPEG)$/;
// word文档
export const wordEx = /^.+.(doc|docx)$/;
// ppt文档
export const pptEx = /^.+.(ppt|pptx)$/;
// excel文档
export const excelEx = /^.+.(xlsx|xls)$/;
// 视频
export const videoEx = /^.+.(mp4|ogg|avi|wmv|mov|mpg)$/;
// 音频
export const audioEx = /^.+.(mp3|wav|ogg|m4a)$/;
// pdf文档
export const pdfEx = /^.+.pdf$/;

// 真实姓名
// const nameRegex = /^[\uDB40DC00-\uDB7FDFFF\.\·\u2022]+(?:\.\·[\uDB40DC00-\uDB7FDFFF\.\·\u2022]+)*$/
export const nameRegex = /^[\u4E00-\u9FA5\uf900-\ufa2d]{2,4}$/;

/**
 * 验证姓名是否正确
 * @param {name} 姓名
 */
export function isValidName(name,) {
  return nameRegex.test(name,) && name.length >= 2 && name.length <= 16;
}

/**
 * 验证身份证是否有效
 * @param {id} 身份证号
 */
export function isValidLastNumberOfId(id,) {
  if (!id || id.length !== 18) return false;

  let length = 18;
  let sum = 0;
  const originLastNumber = id[length - 1].toUpperCase();
  const bases = '7 9 10 5 8 4 2 1 6 3 7 9 10 5 8 4 2'.split(' ',);
  const mapping = '1 0 X 9 8 7 6 5 4 3 2'.split(' ',);
  let lastNumber;

  length--;

  while (--length >= 0) {
    sum += id[length] * bases[length];
  }

  // eslint-disable-next-line prefer-const
  lastNumber = mapping[sum % 11];

  return lastNumber === originLastNumber;
}

/**
 * 金额校验补0
 * @param money
 */
export function validateMoneyZero(money,) {
  return Number(money,).toFixed(2,).toString();
}

/**
 * 数字转中文
 * @param money
 */
export function convertToChinaNum(num,) {
  const arr1 = new Array('零', '一', '二', '三', '四', '五', '六', '七', '八', '九',);
  const arr2 = new Array('', '十', '百', '千', '万', '十', '百', '千', '亿', '十', '百', '千', '万', '十', '百', '千', '亿',);// 可继续追加更高位转换值
  if (!num || isNaN(num,)) {
    return '零';
  }
  const english = num.toString().split('',);
  let result = '';
  for (let i = 0; i < english.length; i++) {
    const des_i = english.length - 1 - i;// 倒序排列设值
    result = arr2[i] + result;
    const arr1_index = english[des_i];
    result = arr1[arr1_index] + result;
  }
  // 将【零千、零百】换成【零】 【十零】换成【十】
  result = result.replace(/零(千|百|十)/g, '零',).replace(/十零/g, '十',);
  // 合并中间多个零为一个零
  result = result.replace(/零+/g, '零',);
  // 将【零亿】换成【亿】【零万】换成【万】
  result = result.replace(/零亿/g, '亿',).replace(/零万/g, '万',);
  // 将【亿万】换成【亿】
  result = result.replace(/亿万/g, '亿',);
  // 移除末尾的零
  result = result.replace(/零+$/, '',);
  // 将【零一十】换成【零十】
  // result = result.replace(/零一十/g, '零十');//貌似正规读法是零一十
  // 将【一十】换成【十】
  result = result.replace(/^一十/g, '十',);
  return result;
}

/**
 * 返回订单状态的中文
 * 订单状态 0.待支付、1.支付完成、2.订单关闭、3.已部分支付、4.已退款、5.订单已取消、6.部分已退款
 * @param money
 */
export function returnPayStatusName(status,) {
  switch (status) {
    case 0:
      return '待支付';
    case 1:
      return '支付完成';
    case 2:
      return '订单关闭';
    case 3:
      return '已部分支付';
    case 4:
      return '已退款';
    case 5:
      return '订单已取消';
    case 6:
      return '部分已退款';
  }
}

/**
 * 防抖
 */
export function debounce(fn, delay,) {
  let timer = null; // 借助闭包
  return function() {
    if (timer) {
      clearTimeout(timer,);
    }
    timer = setTimeout(fn, delay,); // 简化写法
  };
}

/**
 * @description 随机字符串
 * @param  {number} e 长度
 */
export function randomString(e,) {
  const b = e || 32;
  const t = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';
  const a = t.length;
  let n = '';
  for (let i = 0; i < b; i++) {
    n += t.charAt(Math.floor(Math.random() * a,),);
  }
  return n;
}

/**
 * @description 下载文件（直接拿到下载链接）
 * @param  {string} url
 * @param  {string} name
 */
export function downloadFileLink(url, name,) {
  const link = document.createElement('a',);
  const fileNameSuffix = name ? name.split('.',) : url.split('filename=',)[1].split('.',);
  const fileName = fileNameSuffix[0] + '.' + fileNameSuffix[1];
  link.style.display = 'none';
  link.href = url;
  link.setAttribute('download', fileName,);
  document.body.appendChild(link,);
  link.click();
  document.body.removeChild(link,);
}
