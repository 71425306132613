<template>
  <div class="client-com">
    <descriptions :column="3">
      <descriptions-item
        :span="2"
        class="row1"
      >
        <template #label>
          <img
            src="@/assets/person.svg"
            alt="name"
            class="icon"
          >姓名
        </template>
        {{ info.name }}
      </descriptions-item>
      <descriptions-item>
        <van-button
          color="#577DF8"
          class="btn"
          @click="bindClient"
        >绑定</van-button>
      </descriptions-item>
      <descriptions-item :span="3">
        <template #label>
          <img
            src="@/assets/tel.svg"
            alt="tel"
            class="icon"
          >手机
        </template>
        {{ contactPhone }}
      </descriptions-item>
      <descriptions-item :span="3">
        <template #label>
          <img
            src="@/assets/weChat.svg"
            alt="weChat"
            class="icon"
          >微信
        </template>
        {{ contactWechat }}
      </descriptions-item>
    </descriptions>
    <div class="bottom">
      <div class="left">
        <div :class="getClass">{{ studentStatus[info.studentStatus] }}</div>
        <div>-{{ info.realName }}-{{ info.phone }}</div>
      </div>
      <div class="right">{{ info.ownerName }}</div>
    </div>
  </div>
</template>

<script>
import Descriptions from 'ant-design-vue/lib/descriptions';
import 'ant-design-vue/lib/descriptions/style';
import { Button, Toast, } from 'vant';
import Utils from '@/script/utils';
import { bindingClient, } from '@/request/api/common';
import { set, } from 'idb-keyval';
import store from '@/store/index';

export default {
  name: 'ClientCom',
  components: {
    Descriptions,
    DescriptionsItem: Descriptions.Item,
    [Button.name]: Button,
  },
  props: {
    info: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      studentStatus: [
        '未报读',
        '已报名',
        '毕业',
        '已退费',
      ],
    };
  },
  computed: {
    contactPhone() {
      return this.info.contactPhone.join('、',);
    },
    contactWechat() {
      return this.info.contactWechat.join('、',);
    },
    getClass() {
      const { info, } = this;
      switch (info.studentStatus) {
        case 0:
          return 'red';
        case 1:
          return 'green';
        default:
          return '';
      }
    },
  },
  methods: {
    bindClient() {
      const { info, } = this;
      if (info.customerId) {
        const externalId = Utils.getLocalstorageStore('externalId',);
        const data = {
          id: info.customerId,
          wxUserId: Utils.getCookie('wxUserId',),
          externalUserid: externalId,
        };
        bindingClient(data,).then(() => {
          Utils.setStore('cardId', info.customerId,);
          set(externalId, info.customerId,).then(() => {
            store.dispatch('bindSuccess',);
            console.log('存储cardId成功',);
          },).catch((err,) => {
            console.error(err, '存储cardId失败',);
          },);
          Toast.success('绑定成功',);
        },);
      } else {
        Toast.fail('请查询后再绑定',);
      }
    },
  },
};
</script>

<style lang="less">
.client-com {
	padding: 15px;
	margin-bottom: 10px;
	background-color: #FFFFFF;
	border-radius: 6px;
	.ant-descriptions {
		border-bottom: 1px dashed rgba(0,0,0,0.08);
		&-row {
			display: flex;
			align-items: center;
			justify-content: space-between;
			.ant-descriptions-item {
				display: flex;
				align-items: center;
				&-label {
					display: flex;
					align-items: center;
					.icon {
						margin-right: 10px;
					}
				}
			}
			.btn {
				width: 48px;
				height: 23px;
				padding: unset;
				border-radius: 4px;
				font-size: 12px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
			}
		}
	}
	.bottom {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-top: 15px;
		.left {
      display: flex;
      align-items: center;
			font-size: 14px;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;
			color: #333333;
      .red {
        color: #FF4A4A;
      }
      .green {
        color: #0FCE66;
      }
		}
		.right {
			font-size: 12px;
			color: #999999;
			&::before {
				content: '归属人：';
			}
		}
	}
}
</style>
