import request from '..';

const BASE_URL = '/customers';

/**
 * @description 获取报名记录
 * @param {Number} id
 */
export function getRegistration(id,) {
  return request({
    url: `${BASE_URL}/${id}/registration-record`,
    method: 'GET',
  },);
}

/**
 * @description 获取沟通过记录
 */
export function getCommunicationLog(id,) {
  return request({
    url: `${BASE_URL}/${id}/communicationLog`,
    method: 'GET',
  },);
}

/**
 * @description 跟进记录
 * @param  {number} id
 * @param  {1 | 2} sort 升降序，1为降序，2为升序
 */
export function followLog(id,) {
  return request({
    url: `${BASE_URL}/${id}/followUpLog?sort=1`,
    method: 'GET',
  },);
}

/**
 * @description 获取静默类型选项
 * @param {object} params
 * @param {1 | 2} type 1-静默、2-联系未果
 */
export function getInvalidTypeOpts(params,) {
  return request({
    url: '/invalidTypes',
    method: 'GET',
    params,
  },);
}

/**
 * @description 编辑评价
 * @param  {Object} data
 * @param  {Number} id
 * @param  {Array} contactPhone 手机号码
 * @param  {Array} contactWechat 微信号
 * @param  {Array} contactQq QQ号
 * @param {Number} evaluation 评价
 * @param {Number} intentionLevel 意向度
 * @param {Number} invalidTypeId 静默类型
 * @param  {Array} label 标签
 * @param {String} remark 跟进备注
 * @param {boolean} updateContact 是否修改联系方式 这个字段是为了后端不报错 因为一改请求太频繁
 */
export function editEvaluation(data,) {
  return request({
    url: `${BASE_URL}/${data.id}/evaluations`,
    method: 'POST',
    data,
  },);
}

/**
 * @description 查询评价
 * @param  {number} cardId
 */
export function getEvaluation(cardId,) {
  return request({
    url: `${BASE_URL}/${cardId}/evaluations`,
    method: 'GET',
  },);
}

/**
 * @description 信息变更
 * @param  {number} id
 * @param  {1 | 2} sort 升降序，1为降序，2为升序
 */
export function infoLog(id,) {
  return request({
    url: `${BASE_URL}/${id}/info-update-log?sort=1`,
    method: 'GET',
  },);
}
