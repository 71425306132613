import axios from 'axios';
import Utils from '@/script/utils';
import { Toast, } from 'vant';
import { getLoginCode, } from '@/script/wxWork';

const BASE_URL = process.env.VUE_APP_BASE_URL;
const BASE_NO_PAI_URL = process.env.VUE_APP_BASE_NO_PAI_URL;

const service = axios.create({
  baseURL: Utils.getCookie('Authorization',) ? BASE_URL : BASE_NO_PAI_URL,
  headers: {
    'Content-Type': 'application/json',
    // 'Access-Control-Allow-Private-Network': true,
  },
},);

// request拦截器
service.interceptors.request.use(
  (config,) => {
    const isLogin = Utils.getCookie('Authorization',);
    if (isLogin) {
      config.baseURL = BASE_URL;
      config.headers['Authorization'] = isLogin; // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    // config.headers['Content-Type'] = 'application/json';
    return config;
  },
  (error,) => {
    Promise.reject(error,);
  },
);

// Add a response interceptor
service.interceptors.response.use((response,) => {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  if (response.status === 200) {
    return response.data;
  }
  return response;
}, (error,) => {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  // eslint-disable-next-line no-unused-vars
  const { data, status, } = error.response;
  switch (status) {
    case 401:
      Utils.delCookie('Authorization',);
      getLoginCode();
      break;
    case 404:
      Toast.fail('404',);
      break;
    default:
      Toast.fail({
        message: data.message,
        duration: 3000,
      },);
      break;
  }
  return Promise.reject(error,);
},);

export default service;
