<template>
  <div class="sign-up-com">
    <div class="top">
      <div class="row1">
        <div class="tag">{{ orderStatus[info.status] }}</div>
        <div class="product">{{ info.productNames.join('、') }}</div>
      </div>
      <div class="row2">
        <div class="project">{{ info.projectNames.join() }}｜</div>
        <div class="owner">{{ info.belongToName }}</div>
      </div>
    </div>
    <div
      v-if="info.paidAmount && info.totalAmount"
      class="bottom"
    >
      <div class="row1">
        <div class="need-pay">{{ validateMoneyZero(info.totalAmount) }}</div>
        <div class="payed">{{ validateMoneyZero(info.paidAmount) }}</div>
      </div>
      <div class="row2">{{ time }}</div>
    </div>
  </div>
</template>

<script>
import { validateMoneyZero, } from '@/script/var';

export default {
  name: 'SignUpCom',
  props: {
    info: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      validateMoneyZero,
      orderStatus: [
        '待支付',
        '支付完成',
        '订单关闭',
        '已部分支付',
        '已退款',
        '订单已取消',
        '部分已退款',
      ],
    };
  },
  computed: {
    time() {
      const { info, } = this;
      return info.paymentTime ? info.paymentTime.replaceAll('-', '/',) : '-';
    },
  },
};
</script>

<style lang="less">
.sign-up-com {
	padding: 15px;
	margin-top: 10px;
	background-color: #F5F7F8;
	border-radius: 6px;
	.top {
		.row1 {
			display: flex;
			align-items: center;
			.tag {
				padding: 0 4px;
        font-size: 12px;
        font-weight: 500;
        color: #0FCE66;
        background: rgba(15, 206, 102, .08);
        border-radius: 2px;
			}
			.product {
				font-weight: 500;
				color: #333333;
				padding-left: 10px;
			}
		}
		.row2 {
			padding: 8px 0 15px;
		}
	}
	.bottom {
		display: flex;
    flex-direction: column;
    align-items: end;
		.row1 {
			display: flex;
			padding-bottom: 6px;
			font-size: 12px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #333333;
			.need-pay::before {
				content: '应缴金额：￥';
			}
			.payed {
				font-weight: bold;
				color: #FF4A4A;
				padding-left: 21px;
				&::before {
					content: '已缴金额：￥';
				}
			}
		}
		.row2::before {
			content: '完成时间：';
		}
	}
	.row2 {
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #999999;
		display: flex;
		.project::before {
			content: '所属项目：';
		}
		.owner::before {
			content: '所属人：';
		}
	}
}
</style>
