import request from '..';

const BASE_URL = '/quickFiles';

/**
 * @description 获取快捷文件列表
 */
export function getFileList() {
  return request({
    url: `${BASE_URL}/all`,
    method: 'GET',
  },);
}
