<template>
  <div class="voice-player-com">
    <div style="display:none">
      <audio
        ref="audio"
        controls="controls"
      >
        <source
          :src="url || ''"
          type="audio/mp3"
        >
      </audio>
    </div>
    <div
      :class="[
        'voice-bg',
        {
          'voice-bg-right': isRight,
        }]"
      @click="handlePlay"
    >
      <img
        v-if="isPlaying"
        src="@/assets/images/voicePlayerCom/icon-player.gif"
        alt="icon-player.gif"
        :class="{
          'is-right': isRight,
        }"
      >
      <img
        v-else
        src="@/assets/images/voicePlayerCom/icon-player.png"
        alt="icon-player.png"
        :class="{
          'is-right': isRight,
        }"
      >
      <span>{{ time || 0 }}"</span>
    </div>
  </div>

</template>

<script>
export default {
  name: 'VoicePlayerCom',
  props: {
    isRight: {
      type: Boolean,
      default: false,
    },
    time: {
      type: Number,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      timer: null,
      isPlaying: false,
      currentTime: 0,
    };
  },
  beforeDestroy() {
    clearTimeout(this.timer,);
  },
  methods: {
    handlePlay() {
      const audio = this.$refs.audio;
      if (!this.isPlaying) {
        audio.play();
        this.isPlaying = true;
        this.watchEnd();
      } else {
        audio.pause();
        clearTimeout(this.timer,);
        this.isPlaying = false;
        audio.currentTime = 0;
      }
    },
    watchEnd() {
      const that = this;
      this.timer = setTimeout(() => {
        that.isPlaying = false;
      }, (that.time + 1) * 1000,);
    },
  },

};
</script>

<style lang="less">
.voice-player-com {
  .voice-bg {
    img {
      width: 15px;
      cursor: pointer;
    }
  }
  .voice-bg-right {
    display: flex;
    flex-direction: row-reverse;
    .is-right {
      transform: scaleX(-1);
    }
  }
}
</style>
