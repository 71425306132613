import request from '..';

const BASE_URL = 'customers/connect/';

/**
 * @description 获取聊天记录
 * @param  {object} params
 * @param {number} recordId 沟通记录id
 * @param {number} querySize 查询数量
 * @param {number} action? 查询动作 1-向上查询(聊天记录向上翻) 2-向下查询(聊天记录向下翻)
 * @param {number} seq? 消息序号（当 action=1, 则查询该消息之前的记录；action=2，则查询该消息之后的记录
 * @returns {number} totalElements 总条数
 * @returns {Array} content 聊天记录
 */
export function getChatList(params,) {
  return request({
    url: `${BASE_URL}chat/get`,
    method: 'GET',
    params,
  },);
}

/**
 * @description 获取聊天记录头像
 * @param  {number} recordId
 * @returns {string} cusAvatar 客户头像
 * @returns {string} userAvatar 系统用户头像
 * @returns {string} cusName 客户微信名
 * @returns {string} userName 系统用户名
 */
export function getChatAvatar(recordId,) {
  return request({
    url: `${BASE_URL}chat/avatar?recordId=${recordId}`,
    method: 'GET',
  },);
}

/**
 * @description 查询学员沟通对象下拉列表
 * @param  {string} externalUserid
 * @returns {number} recordId
 * @returns {string} userName 系统用户名
 */
export function getCommunicators(externalUserid,) {
  return request({
    url: `${BASE_URL}list/communicators?externalUserid=${externalUserid}`,
    method: 'GET',
  },);
}
