<template>
  <div
    :class="['chat-record-item',
             {
               'is-right': !item.isCustomerSend,
             }]"
  >
    <div
      :style="{
        'height': '100%',
        'textAlign': item.isCustomerSend ? 'left' : 'right'
      }"
    >
      <div
        class="chat-record-item-container"
        :style="{
          'justifyContent': item.isCustomerSend ? 'unset' : 'right'
        }"
      >
        <img
          v-if="item.isCustomerSend"
          :src="avatar"
          class="avatar"
        >
        <div class="container">
          <div class="name-and-time">
            <div class="name">
              {{ name }}
            </div>
            <div
              class="time"
            >{{ moment(`${item.msgtime}`, 'x').format("YYYY-MM-DD HH:mm:ss") }}</div>
          </div>
          <div
            class="content"
            :style="{
              backgroundColor: item.isCustomerSend ? '#F6F8FF' : '#577DF8',
              color: item.isCustomerSend ? 'rgba(0, 0, 0, 0.85)' : '#FFFFFF'
            }"
          >
            <div
              v-if="item.msgtype === 'card' || item.msgtype === 'external_redpacket'"
            >
              {{ getConent(item) }}
            </div>
            <div
              v-if="item.msgtype === 'text'"
              style="white-space: pre-wrap;"
            >
              {{ item.isRecall ? `${Recall_Content}${item.text.content}` : item.text.content }}
            </div>
            <div
              v-if="item.msgtype === 'voice'"
            >
              <div
                v-if="item.mark"
              >
                <voice-player-com
                  :url="item.fileUrl"
                  :is-right="!item.isCustomerSend"
                  :time="item.voice.play_length"
                />
                <p>{{ item.isRecall ? `${Recall_Content}${item.voice.content}` : item.voice.content }}</p>
              </div>
              <div
                v-else
                class="tip"
                @click="tip(item.msgtype)"
              >
                {{ item.isRecall ? `${Recall_Content}语音还未同步` : '语音还未同步' }}
              </div>
            </div>
            <div
              v-if="item.msgtype === 'meeting_voice_call'"
            >
              <audio controls>
                <source
                  :src="item.fileUrl"
                  type="audio/mpeg"
                >
              </audio>
            </div>

            <div
              v-if="item.msgtype === 'emotion' || item.msgtype === 'image'"
            >
              <span>
                {{ item.isRecall ? `${Recall_Content}` : '' }}
              </span>
              <img
                v-if="item.msgtype === 'emotion'"
                :src="item.fileUrl"
                alt="emotion"
                class="emotion"
              >
              <van-image
                v-else
                :src="item.fileUrl"
                width="150"
                alt="image"
                @click="preview(item.fileUrl)"
              />
            </div>
            <div
              v-if="item.msgtype === 'link'"
              class="link"
            >
              <h4>{{ item.isRecall ? `${Recall_Content}${item.link.description}` : item.link.description }}</h4>
              <a
                :href="item.link.link_url"
                target="_blank"
              >
                {{ item.link.link_url }}
              </a>
            </div>
            <div
              v-if="item.msgtype === 'file' || item.msgtype === 'video'"
              class="file"
            >
              <a
                v-if="item.mark"
                :href="item.fileUrl"
                target="_blank"
              >
                {{ item.isRecall ?
                  `${Recall_Content}${item.msgtype === 'file' ? item.file.filename : '视频链接'}`
                  : (item.msgtype === 'file' ? item.file.filename : '视频链接') }}
              </a>
              <div
                v-else
                class="tip"
                @click="tip(item.msgtype)"
              >
                {{ item.isRecall ?
                  `${Recall_Content}${item.msgtype === 'file' ? item.file.filename : '视频链接'}`
                  : (item.msgtype === 'file' ? item.file.filename : '视频链接') }}
              </div>
            </div>
          </div>
        </div>
        <img
          v-if="!item.isCustomerSend"
          :src="avatar"
          class="avatar"
        >
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import VoicePlayerCom from '@/components/voicePlayerCom/index.vue';
import { validateMoneyZero, } from '@/script/var';
import { Image as VanImage, ImagePreview, } from 'vant';

export default {
  name: 'ChatRecordItem',
  components: {
    VoicePlayerCom,
    [VanImage.name]: VanImage,
    [ImagePreview.Component.name]: ImagePreview.Component,
  },
  props: {
    item: {
      required: true,
      type: Object,
    },
    avatar: {
      required: true,
      type: String,
    },
    name: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      moment,
      isShow: true,
      Recall_Content: '撤回消息：',
      msgType: {
        voice: '语音',
        file: '文件',
        video: '视频',
      },
    };
  },
  methods: {
    getConent(item,) {
      const Recall_Content = '撤回消息：';
      const Card = '[推荐名片]';
      const Red_Packet = '[红包]';
      switch (item.msgtype) {
        // case 'text':
        //   return item.isRecall ? `${Recall_Content}${item.text.content}` : item.text.content;
        case 'card':
          return item.isRecall ? `${Recall_Content}${Card}` : `${Card}`;
        case 'external_redpacket':
          return item.isRecall
            ? `${Recall_Content}${Red_Packet}`
            : `${Red_Packet}${validateMoneyZero(item.redpacket.totalamount / 100,)}元`;
        default:
          break;
      }
    },
    tip(type,) {
      const { $message, msgType, } = this;
      $message({
        type: 'warning',
        message: `${msgType[type]}还未同步`,
      },);
    },
    preview(url,) {
      ImagePreview([
        url,
      ],);
    },
  },
};
</script>

<style lang="less">
@import "/src/style/common";

.chat-record-item {
  padding: 10px 0;
  .is-right {
    display: flex;
    flex-direction: row-reverse;
  }
  .chat-record-item-container {
    display: flex;
    align-items: flex-start;
    .avatar {
      width: 36px;
      border-radius: 50%;
    }
    .container {
      padding: 0 10px;
      .name-and-time {
        display: flex;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: @base-font-weight;
        color: rgba(0, 0, 0, 0.5);
        .name {
          padding-right: 4px;
        }
      }
    }
    .content {
      padding: 10px;
      margin: 10px;
      border-radius: 10px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: @base-font-weight;
      text-align: left;
      a {
        word-break: break-all;
        color: #0027A5;
      }
      .emotion {
        width: 90px;
      }
    }
  }
}
</style>
