<template>
  <div class="follow-up-details">
    <div class="profile_name">
      <van-card
        :title="baseInfo.remarkName"
        :desc="baseInfo.wxName"
        :thumb="baseInfo.avatar"
      />
    </div>
    <van-divider />
    <!-- 报名记录 -->
    <div class="drawer_sign">
      <p class="drawer_person_msg">报名记录</p>
      <van-empty
        v-if="!signRecordList.length"
        class="drawer_div"
        description="还没有任何报名记录哦"
      />
      <div
        v-else
        class="drawer_div"
      >
        <div class="sign_msg">
          <div
            v-for="item in signRecordList"
            :key="item.id"
            class="sign_div"
          >
            <div>
              <span class="sign_div_status">{{ signStatusList[item.status].name }}</span>
              <span>完成时间：{{ item.updateTime }}</span>
              <span>归属人：{{ item.belongToName }}</span>
            </div>
            <div>
              <span>报名产品：<span
                v-for="pItem in item.products"
                :key="pItem.id"
              >{{ pItem.name }}、</span></span>
              <span>所属项目：<span
                v-for="pItem in item.products"
                :key="pItem.id"
              >{{ pItem.projectName }}、</span></span>
            </div>
            <div>
              <span>应缴金额：{{ item.orderInfoAmount }}元</span>
              <span>已缴金额：{{ item.paidAmount }}元</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <van-divider />
    <!-- 沟通记录 -->
    <div class="drawer_chat">
      <p class="drawer_person_msg">沟通记录</p>
      <van-empty
        v-if="!chatRecordList"
        class="drawer_div"
        description="还没有任何沟通记录哦"
      />
      <div
        v-else
        class="drawer_div"
      >
        <div
          v-for="item in chatRecordList"
          :key="item.id"
        >
          <div class="drawer_div_chat">
            <div class="drawer_div_chat_span">
              <span class="sign_div_status">{{ chatRecordType[item.type].name }}:</span>
              <span>{{ item.createTime }}</span>
            </div>
            <p class="drawer_content">{{ item.detail }}</p>
            <p class="drawer_div_chat_people">操作人：{{ item.createBy }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCustomersInfo, } from '@/request/api/customerProfile';
import { getRegistration, getCommunicationLog, } from '@/request/api/followUpDetails';
import { Card, Divider, Toast, Empty, } from 'vant';
import Utils from '@/script/utils';
import { get, } from 'idb-keyval';

export default {
  name: 'FollowUpDetails',
  components: {
    [Card.name]: Card,
    [Divider.name]: Divider,
    [Toast.name]: Toast,
    [Empty.name]: Empty,
  },
  data() {
    return {
      baseInfo: {},
      signRecordList: [],
      signStatusList: [
        { id: 0, name: '待支付', },
        { id: 1, name: '支付完成', },
        { id: 2, name: '订单关闭', },
        { id: 3, name: '已部分支付', },
        { id: 4, name: '已退款', },
        { id: 5, name: '订单已取消', },
        { id: 6, name: '部分已退款', },
        { id: 7, name: '支付待确定', },
      ],
      chatRecordList: [],
      chatRecordType: [
        { id: 0, name: '新增客户', },
        { id: 1, name: '呼出呼入录音', },
        { id: 2, name: '来访记录', },
        { id: 3, name: '跟进备注', },
        { id: 4, name: '意向反馈', },
        { id: 5, name: '意向度评价', },
        { id: 6, name: '订单记录', },
        { id: 7, name: '用户基本信息变更', },
      ],
      userId: 0, // 绑定客户id
    };
  },
  created() {
    const externalId = Utils.getLocalstorageStore('externalId',);
    get(externalId,).then((val,) => {
      this.userId = val;
      this.getCustomersList();
      this.getRegistedList();
      this.getComLog();
    },);
  },
  methods: {
    // 获取客户信息
    getCustomersList() {
      getCustomersInfo(this.userId,).then((res,) => {
        this.baseInfo = res;
      },);
    },
    // 查询报名记录
    getRegistedList() {
      getRegistration(this.userId,).then((res,) => {
        if (res) {
          this.signRecordList = res;
        }
      },);
    },
    // 查询沟通记录
    getComLog() {
      getCommunicationLog(this.userId,).then((res,) => {
        if (res) {
          this.chatRecordList = res;
        }
      },);
    },
  },
};
</script>

<style lang="less" scoped>
.follow-up-details{
  color: #000;
  font-size: 13px;
  .profile_name{
    .van-card{
      background-color: #ffffff;
    }
    .van-card__title{
      max-height: 60px;
      font-weight: 550;
      line-height: 50px;
      font-size: 20px;
    }
    .van-card__desc{
      max-height: 50px;
      line-height: 30px;
    }
  }
  .drawer_person_msg{
    font-size: 16px;
    font-weight: bold;
    padding: 0;
    margin: 0;
    margin-bottom: 5px;
  }
  .drawer_sign, .drawer_chat{
    height: 250px;
    font-size: 16px;
    font-weight: 600;
  }
  .drawer_chat{
    margin-bottom: 40px;
  }
  .drawer_content{
    margin: 10px 30px;
    font-size: 15px;
    padding: 1px;
  }
  .drawer_div{
    height: 200px;
    overflow: scroll;
  }
  .sign_msg{
    height: 200px;
    overflow: scroll;
  }
  .sign_div{
    background-color: #F0F0F0;
    padding: 5px;
    margin-bottom: 10px;
  }
  .sign_div_status{
    color:	#46A3FF;
  }
  .sign_div span{
    font-size: 14px;
    line-height: 20px;
    margin-right: 10px;
  }
  .drawer_div_chat{
    background-color: #F0F0F0;
    .drawer_div_chat_span{
      padding: 5px 5px 0px 5px;
      display: flex;
      justify-content: space-between;
      span:nth-child(1){
        font-size: 13px;
        font-weight: bold;
      }
      span:nth-child(2){
        font-size: 10px;
      }
    }
    .drawer_div_chat_people{
      font-size: 10px;
      padding: 0px 0px 5px 5px;
      color: #949494;
    }
  }
}
</style>
