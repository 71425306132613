import request from '..';

const BASE_URL = '/customers';

/**
 * @description 获取客户信息
 * @param id {Number} 绑定的客户id
 */
export function getCustomersInfo(id,) {
  return request({
    url: `${BASE_URL}/wx-sidebar/info?id=${id}`,
    method: 'GET',
  },);
}

/**
 * @description 查询单个名片
 * @param  {String} id 客户ID或联系方式
 */
export function getCustomerInfo(id,) {
  return request({
    url: `${BASE_URL}/info/${id}`,
    method: 'GET',
  },);
}

/**
 * @description 获取评价
 * @param id {Number} 绑定的客户id
 */

export function getCustomersEval(id,) {
  return request({
    url: `${BASE_URL}/${id}/evaluations`,
    method: 'GET',
  },);
}

/**
 * @description 获取无效类型
 * @param {Boolean} enabled：true(只查可用的)
 */
export function getInvalidType(data,) {
  return request({
    url: `/invalidTypes?enabled=true`,
    method: 'GET',
    data,
  },);
}

/**
 * @description 获取标签列表
 */
export function getCustomerLabel() {
  return request({
    url: `/customerLabel?enabled=${true}`,
    method: 'GET',
  },);
}

/**
 * @description 编辑评价
 * @param  {number} id,名片id
 * @params {Object}
 * @param {Number} evaluation 评价
 * @param {Array} label 标签
 * @param {Number} intentionLevel 意向度
 * @param {Number} intentionLevel 无效类型
 */
export function editEvaluations(params, id,) {
  return request({
    url: `${BASE_URL}/${id}/evaluations`,
    method: 'POST',
    data: params,
  },);
}

/**
 * @description 获取意向信息
 * @param {String} 名片id
 */
export function getIntentionInfo(id,) {
  return request({
    url: `${BASE_URL}/${id}/eff-intentions`,
    method: 'GET',
  },);
}

/**
 * @description 新增意向
 * @param  {number} customerId,名片id
 * @param {Number} intentionAmount,意向报名金额
 * @param {String} intentionTime, 意向报名时间
 */
export function addIntentions(params,) {
  return request({
    url: `${BASE_URL}/intentions`,
    method: 'POST',
    data: params,
  },);
}

/**
 * @description 编辑意向
 * @param  {Number} id 意向id
 * @param {Number} customerId 客户id
 * @param {String} intentionTime 意向时间
 * @param {String} intentionAmount 意向金额
 */
export function editIntentions(data,) {
  return request({
    url: `${BASE_URL}/intentions/${data.id}`,
    method: 'PUT',
    data,
  },);
}

/**
 * @description 编辑标签
 * @param  {Array} labelIds,标签id
 */
export function editLabel(data,) {
  return request({
    url: `${BASE_URL}/wx-sidebar/label`,
    method: 'PUT',
    data,
  },);
}

/**
 * @description 查询学生订单产品
 * @param  {number} customerId
 */
export function getOrder(customerId,) {
  return request({
    url: `${BASE_URL}/connect/orderAndProduct/${customerId}`,
    method: 'GET',
  },);
}
