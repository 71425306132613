<template>
  <div
    class="chat-record"
  >
    <div class="search">
      <a-select
        v-model="recordId"
        :options="options"
        placeholder="请选择沟通对象"
      />
      <a-input-search
        v-model="params.chatContent"
        placeholder="请选择沟通对象后再进行关键词搜索"
        @search="handleSearch"
      />
    </div>
    <div
      v-infinite-scroll-down="load"
      :infinite-scroll-disabled="disabled"
      :infinite-scroll-distance="1020"
      class="chat-record-list-container"
    >
      <div
        class="chat-record-list"
      >
        <chat-record-item
          v-for="item in list"
          :key="item.seq"
          :item="item"
          :avatar="item.isCustomerSend ? avatarAndName.cusAvatar : avatarAndName.userAvatar"
          :name="item.isCustomerSend ? avatarAndName.cusName : avatarAndName.userName"
        />
        <p
          v-if="loading"
          class="end"
        >加载中...</p>
        <p
          v-else-if="noMore"
          class="end"
        >没有更多了</p>
        <p
          v-else-if="list.length"
          class="end"
          @click="load"
        >点击加载更多</p>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import ChatRecordItem from '@/components/chatRecordCom/index.vue';
import { getChatList, getChatAvatar, getCommunicators, } from '@/request/api/chatRecord';
import {
  getScrollContainer,
} from '@/components/chatRecordCom/infinite-scroll/getScrollContainer';
import { throttle, } from 'throttle-debounce';
import { getScrollOptions, handleScroll, scope, } from '@/components/chatRecordCom/infinite-scroll/src/main';
import ASelect from 'ant-design-vue/lib/select';
import AInput from 'ant-design-vue/lib/input';
import AInputSearch from 'ant-design-vue/lib/input/Search';
import 'ant-design-vue/lib/input/style';
import Utils from '@/script/utils';
import { Toast, } from 'vant';

export default {
  name: 'ChatRecord',
  components: {
    ChatRecordItem,
    ASelect,
    // 为了解决[Vue warn]: Failed to resolve directive: ant-input
    // eslint-disable-next-line vue/no-unused-components
    AInput,
    AInputSearch,
  },
  directives: {
    InfiniteScrollDown: {
      inserted(el, binding, vnode,) {
        const cb = binding.value;
        const vm = vnode.context;
        // only include vertical scroll
        const container = getScrollContainer(el, true,);

        const { delay, immediate, } = getScrollOptions(el, vm,);
        const onScroll = throttle(delay, handleScroll.bind(el, cb,),);

        el[scope] = { el, vm, container, onScroll, };

        if (container) {
          container.addEventListener('scroll', onScroll,);

          if (immediate) {
            const observer = el[scope].observer = new MutationObserver(onScroll,);
            observer.observe(container, { childList: true, subtree: true, },);
            onScroll();
          }
        }
      },
      unbind(el,) {
        const { container, onScroll, } = el[scope];
        if (container) {
          container.removeEventListener('scroll', onScroll,);
        }
      },
    },
  },
  data() {
    return {
      recordId: null,
      list: [],
      loading: false,
      noMore: false,
      isInit: true,
      avatarAndName: {
        cusAvatar: '',
        cusName: '',
        userAvatar: '',
        userName: '',
      },
      params: {
        action: null,
        seq: null,
        chatContent: null,
      },
      totalElements: null,
      options: [],
    };
  },
  computed: {
    disabled() {
      return this.loading || this.noMore;
    },
  },
  watch: {
    recordId() {
      const { isInit, noMore, load, totalElements, params, } = this;
      !isInit && (this.isInit = true);
      noMore && (this.noMore = false);
      params.action && (params.action = null);
      params.seq && (params.seq = null);
      params.chatContent && (params.chatContent = null);
      totalElements && (this.totalElements = null);
      load();
    },
  },
  created() {
    this.getCommunicatorsOption();
  },
  methods: {
    load() {
      const { getChatList, isInit, list, params, $nextTick, } = this;
      if (isInit) {
        getChatList(true,).then(() => {
          this.isInit = false;
          // const el = document.getElementsByClassName('chat-record-list-container',)[0];
          const el = document.getElementById('app',);
          const height = el.scrollHeight;
          el.scrollTo(0, -height,);
        },);
      } else {
        params.action = 1;
        params.seq = list[list.length - 1].seq;
        getChatList(false,);
      }
    },
    async getChatList(isInit = false,) {
      const { recordId, list, params, getAvatar, } = this;
      if (recordId) {
        const data = {
          recordId, // 123
          querySize: 10,
          ...params,
        };
        this.loading = true;
        const res = await getChatList(data,);
        const { content, totalElements, } = res;
        isInit ? (this.list = content) : list.push(...content,);
        // 有条数才获取头像
        isInit && content.length && getAvatar();
        this.totalElements = totalElements;
        this.loading = false;
        if (this.list.length >= totalElements) {
          this.noMore = true;
        }
      }
    },
    async getAvatar() {
      const res = await getChatAvatar(this.recordId,);
      this.avatarAndName = res;
    },
    async getCommunicatorsOption() {
      const externalId = Utils.getLocalstorageStore('externalId',);
      const res = await getCommunicators(externalId,);
      this.options = res.map((item,) => ({
        label: item.userName,
        value: item.recordId,
      }),);
    },
    handleSearch(searchValue,) {
      const { recordId, isInit, load, } = this;
      if (recordId) {
        !isInit && (this.isInit = true);
        load();
      } else {
        Toast.fail('请选择沟通对象后再进行关键词搜索',);
      }
    },
  },
};
</script>

<style lang="less">
.chat-record {
	.search {
		position: sticky;
    top: 50px;
    background-color: white;
		display: flex;
    justify-content: space-between;
    padding-top: 27px;
		.ant- {
			&select {
				width: 151px;
			}
			&input-search {
				width: 151px;
			}
		}
	}
  &-list-container {
    overflow: scroll;
  }
	&-list {
		margin: 0 auto;
		display: flex;
		flex-direction: column-reverse;
		.end {
			margin: 20px auto;
			text-align: center;
		}
	}
}
</style>
