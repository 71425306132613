import request from '..';

/**
 * @description 企业微信授权登录
 * @param {number} type 授权应用 1-深大优课(默认) 3-侧边栏
 * @param {string} code 企业微信返回的code
 * @returns {string} token 登录token
 * @returns {string} wxUserId 登录用户企业微信userid
 */
export function crmLogin(code,) {
  return request({
    url: `auth/wxLogin?type=3&code=${code}`,
    method: 'GET',
  },);
}

/**
 * @description 获取企业微信Ticket
 * @returns {string} appTicket 应用ticket
 * @returns {string} corpTicket 企业ticket
 */
export function getTicket() {
  return request({
    url: 'wx-work/sidebar/ticket',
    method: 'GET',
  },);
}

/**
 * @description 绑定客户前，查询客户
 * @param  {number | string} num 联系方式
 */
export function getCard(num,) {
  return request({
    url: `customers/listByPhoneOrWechat/${num}`,
    method: 'GET',
  },);
}

/**
 * @description 绑定客户
 * @param  {object} data
 * @param {number} id 名片ID
 * @param {string} wxUserId 企业微信用户ID
 * @param {string} externalUserid 外部用户ID
 */
export function bindingClient(data,) {
  return request({
    url: 'customers/wx-sidebar/binding',
    method: 'PUT',
    data,
  },);
}

/**
 * @description 获取sha1加密签名
 * @param  {object} params
 * @param {string} jsapi_ticket
 * @param {string} nonceStr 随机串
 * @param {string} timestamp 时间戳
 * @param {string} url 当前网页的URL，不包含#及其后面部分
 */
export function getSha1(params,) {
  return request({
    url: 'wx-work/sign',
    method: 'GET',
    params,
  },);
}

/**
 * @description 获取外部联系人是否已绑定名片
 * @param  {object} params
 * @param  {string} externalUserId 企业微信外部联系人id
 * @return  {string} 名片id
 */
export function getIsBind(params,) {
  return request({
    url: 'customers/wx-sidebar/getCustomerId',
    method: 'GET',
    params,
  },);
}
