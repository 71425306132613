import Vue from 'vue';
import Vuex from 'vuex';
import { getIsBind, } from '@/request/api/common';
import Utils from '@/script/utils';

Vue.use(Vuex,);

const store = new Vuex.Store({
  state: {
    isBind: false,
  },
  mutations: {
    BindSuccess(state,) {
      state.isBind = true;
    },
    BindFail({ isBind, },) {
      isBind = false;
    },
  },
  actions: {
    bindSuccess({ commit, state, },) {
      return new Promise((resolve,) => {
        commit('BindSuccess',);
        if (state.isBind) {
          resolve(null,);
        }
      },);
    },
    bindFail() {
      this.commit('BindFail',);
    },
    async getIsBind() {
      const res = await getIsBind({ externalUserId: Utils.getLocalstorageStore('externalId',), },);
      if (typeof res === 'number') {
        this.commit('BindSuccess',);
        Utils.setStore('cardId', res,);
      }
    },
  },
  modules: {
  },
},);

export default store;
