<template>
  <div class="intro">
    <div class="card">
      <descriptions
        :column="2"
        class="top"
      >
        <descriptions-item label="咨询项目">{{ info.secondProjectName }}</descriptions-item>
        <descriptions-item label="渠道来源">{{ info.sourceName }}</descriptions-item>
        <descriptions-item
          :span="2"
          label="手机"
        >{{ phone }}</descriptions-item>
        <descriptions-item
          :span="2"
          label="微信"
        >{{ weChat }}</descriptions-item>
        <descriptions-item
          :span="2"
          label="QQ"
        >{{ qq }}</descriptions-item>
      </descriptions>
      <descriptions :column="4">
        <descriptions-item
          :span="3"
          label="真实姓名"
        >{{ info.realName || '-' }}</descriptions-item>
        <descriptions-item class="tag">
          <van-tag
            size="medium"
            :color="getColor(info.registered, true)"
            :text-color="getColor(info.registered, false)"
          >{{ info.registered ? '已报名' : '未报名' }}</van-tag>
        </descriptions-item>
        <descriptions-item
          :span="3"
          label="报读手机"
        >{{ info.phone || '-' }}</descriptions-item>
      </descriptions>
    </div>
    <div
      v-if="signupRecord.length"
      class="card"
    >
      <div class="title">
        报名记录
      </div>
      <sign-up-com
        v-for="item in signupRecord"
        :key="item.productNames.join('、')"
        :info="item"
      />
    </div>
    <div class="bottom-btn">
      <!-- 被评价为有效的数据才能加入意向 -->
      <van-button
        :disabled="info.intentional || [1, 2].includes(info.studentStatus) || !!evaluationData.evaluation"
        color="#577DF8"
        plain
        @click="handleNew"
      >{{ info.intentional ? '已生成意向' : '新增意向' }}</van-button>
      <!-- 咨询评价选中为'有效'才可报名哦 -->
      <van-button
        :disabled="!!evaluationData.evaluation"
        color="#577DF8"
        @click="handleToSignup"
      >{{ [1, 2].includes(info.studentStatus) ? '已报名' : '报名' }}</van-button>
    </div>
    <new-intention-com
      :is-popup="isPopup"
      :popup="popup"
      @close="handleNew"
      @refresh="initInfo"
    />
  </div>
</template>

<script>
import Descriptions from 'ant-design-vue/lib/descriptions';
import 'ant-design-vue/lib/descriptions/style';
import signUpCom from './signUpCom.vue';
import { Button, Tag, } from 'vant';
import Utils from '@/script/utils';
import { openDefaultBrowser, } from '@/script/wxWork';
import newIntentionCom from './newIntentionCom.vue';
import { getCustomerInfo, getOrder, } from '@/request/api/customerProfile';

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Intro',
  components: {
    Descriptions,
    DescriptionsItem: Descriptions.Item,
    signUpCom,
    [Button.name]: Button,
    [Tag.name]: Tag,
    newIntentionCom,
  },
  props: {
    evaluationData: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      isPopup: false,
      info: {},
      cardId: Utils.getStore('cardId',),
      signupRecord: [],
    };
  },
  computed: {
    phone() {
      const { info, } = this;
      return info.contactPhone ? info.contactPhone.join('、',) : '-';
    },
    weChat() {
      const { info, } = this;
      return info.contactWechat ? info.contactWechat.join('、',) : '-';
    },
    qq() {
      const { info, } = this;
      return info.contactQq ? info.contactQq.join('、',) : '-';
    },
    popup() {
      const { info, phone, weChat, } = this;
      return {
        id: info.id,
        name: info.name,
        phone,
        weChat,
      };
    },
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      const { initInfo, getSignupRecord, } = this;
      await initInfo();
      getSignupRecord();
    },
    async initInfo() {
      const { cardId, } = this;
      const res = await getCustomerInfo(cardId,);
      this.info = res;
      Utils.setStore('ownerId', res.ownerId,);
    },
    handleToSignup() {
      // TODO判断是否为有效
      const { cardId, info, phone, } = this;
      const orderUrl = process.env.VUE_APP_UOOC_ADMIN_URL;
      const token = encodeURI(Utils.getCookie('Authorization',),);
      const pageUrl = encodeURIComponent(`/studentCenter/studentMessage?type=add&id=${cardId}&name=${info.name}&phone=${phone}&way=crm`,);
      openDefaultBrowser(`${orderUrl}?t=${token}&p=${pageUrl}`,);
    },
    handleNew() {
      this.isPopup = !this.isPopup;
    },
    async getSignupRecord() {
      const res = await getOrder(this.info.id,);
      this.signupRecord = res;
      Utils.setStore('order', res,);
    },
    getColor(isRegistered, isBg,) {
      if (isRegistered) {
        if (isBg) {
          return 'rgba(15, 206, 102, 0.08)';
        } else {
          return '#0FCE66';
        }
      } else {
        if (isBg) {
          return 'rgba(255, 74, 74, 0.08)';
        } else {
          return '#FF4A4A';
        }
      }
    },
  },
};
</script>

<style lang="less">
.intro {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 15px;
  .card {
    padding: 15px;
    margin-bottom: 10px;
    background: #FFFFFF;
    border-radius: 6px;
    .ant-descriptions-item {
      &-label {
        color: #999999;
      }
      &-content {
        color: #333333;
      }
    }
    .top {
      border-bottom: 1px dashed rgba(0,0,0,0.08);
      margin-bottom: 15px;
    }
    .tag {
      .ant-descriptions-item-content {
        padding: 0 4px;
        font-size: 12px;
        font-weight: 500;
        color: #0FCE66;
        background: rgba(15, 206, 102, .08);
        border-radius: 2px;
      }
    }
    .title {
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
    }
  }
  .bottom-btn {
    width: 346px;
    margin: 0 auto;
    flex: 1;
    display: flex;
    justify-content: space-around;
    align-items: end;
    .van-button--normal {
      width: 167px;
      border-radius: 4px;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
    }
  }
}
</style>
