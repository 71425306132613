<template>
  <div class="follow-detail">
    <div class="card">
      <div class="title">
        跟进记录
      </div>
      <note-com
        v-for="item in followLog"
        :key="item.id"
        :note-info="item"
      />
    </div>
    <div class="card">
      <div class="title">
        信息变更
      </div>
      <note-com
        v-for="item in showNoteList"
        :key="item.id"
        :note-info="item"
      />
    </div>
    <div
      v-if="noteList.length > 1"
      class="more"
      @click="handleMore"
    >
      <div class="text">查看更多</div>
      <img
        src="@/assets/more_icon.svg"
        alt="more_icon"
      >
    </div>
    <a-form-model
      ref="form"
      layout="inline"
      :model="form"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
      class="card"
    >
      <div class="ant-top">
        <a-form-model-item prop="evaluation">
          <a-radio-group
            v-model="form.evaluation"
            button-style="solid"
            @change="handleFormEvaluation"
          >
            <a-radio-button
              v-for="item in evaluationOpt"
              :key="item.id"
              :value="item.id"
            >
              {{ item.label }}
            </a-radio-button>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item
          v-if="form.evaluation === 0"
          label="意向度"
          prop="intentionLevel"
        >
          <a-select
            v-model="form.intentionLevel"
            placeholder="请选择"
            class="intention-level"
          >
            <a-select-option
              v-for="item in intentionOpt"
              :key="item.id"
              :value="item.id"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item
          v-if="form.evaluation === 1"
          label="静默理由（提交此评价将会舍弃当前名片）"
          prop="invalidTypeId"
        >
          <a-select
            v-model="form.invalidTypeId"
            placeholder="请选择"
            class="invalid-type-id"
          >
            <a-select-option
              v-for="item in invalidTypeOpt"
              :key="item.id"
              :value="item.id"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item
          v-if="form.evaluation === 2"
          label="联系未果"
          prop="invalidTypeId"
        >
          <a-select
            v-model="form.invalidTypeId"
            placeholder="请选择"
            class="invalid-type-id"
          >
            <a-select-option
              v-for="item in contactNoResultOpt"
              :key="item.id"
              :value="item.id"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </div>
      <a-form-model-item prop="remark">
        <a-text-area
          v-model="form.remark"
          placeholder="请输入…"
        />
      </a-form-model-item>
      <a-form-model-item class="btns">
        <a-button @click="resetForm">清空还原</a-button>
        <a-button
          :disabled="disabled"
          :loading="loading"
          type="primary"
          @click="onSubmit"
        >修改并提交</a-button>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import NoteCom from './noteCom.vue';
import { FormModel, Select, Input, Radio, Button, } from 'ant-design-vue';
import Utils from '@/script/utils';
import { followLog, getInvalidTypeOpts, editEvaluation, infoLog, } from '@/request/api/followUpDetails';
import { Toast, } from 'vant';

export default {
  name: 'FollowDetail',
  components: {
    NoteCom,
    ARadioGroup: Radio.Group,
    ARadioButton: Radio.Button,
    AFormModel: FormModel,
    AFormModelItem: FormModel.Item,
    ASelect: Select,
    ASelectOption: Select.Option,
    ATextArea: Input.TextArea,
    AButton: Button,
  },
  props: {
    evaluationData: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      isMore: false,
      followLog: [],
      noteList: [],
      showNoteList: [],
      form: {
        evaluation: undefined,
        intentionLevel: undefined,
        invalidTypeId: undefined,
        remark: '',
      },
      evaluationOpt: [
        {
          id: 2,
          label: '联系未果',
        },
        {
          id: 0,
          label: '有效',
        },
        {
          id: 1,
          label: '建议静默',
        },
      ],
      intentionOpt: [ // 意向度
        {
          id: 1,
          label: 'A-意向很高',
        },
        { id: 2,
          label: 'B-意向较高',
        },
        { id: 3,
          label: 'C-意向中等',
        },
        { id: 4,
          label: 'D-意向较低',
        },
        { id: 5,
          label: 'E-意向很低',
        },
      ],
      labelCol: { span: 1, },
      wrapperCol: { span: 2, },
      cardId: Utils.getStore('cardId',),
      userId: Utils.getCookie('userId',),
      ownerId: Utils.getStore('ownerId',),
      rules: {
        intentionLevel: [
          { required: true, message: '请选择意向度', trigger: 'blur', },
        ],
        invalidTypeId: [
          { required: true, message: '请选择理由', trigger: 'blur', },
        ],
      },
      invalidTypeOpt: [],
      contactNoResultOpt: [],
      loading: false,
    };
  },
  computed: {
    disabled() {
      const { userId, ownerId, form, } = this;
      // 归属人才能评价
      return userId !== ownerId || form.evaluation === undefined;
    },
  },
  watch: {
    'form.evaluation'(newVal, oldVal,) {
      const { invalidTypeOpt, form, getInvalidTypesList, contactNoResultOpt, } = this;
      if (newVal === 1) {
        // 咨询评价选中静默时，意向度只能选择F-静默数据6
        form.intentionLevel = 6;
        !invalidTypeOpt.length && getInvalidTypesList(1,);
      } else if (oldVal === 1) {
        form.intentionLevel = undefined;
      }
      if (newVal === 2) {
        !contactNoResultOpt.length && getInvalidTypesList(2,);
      }
    },
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      const { cardId, evaluationData, form, initFollowLog, } = this;
      form.evaluation = evaluationData.evaluation;
      form.intentionLevel = evaluationData.intentionLevel;
      form.invalidTypeId = evaluationData.invalidTypeId;
      // 调获取名片接口
      initFollowLog();
      const res = await infoLog(cardId,);
      this.noteList = res;
      this.showNoteList = [this.noteList[0],
      ];
    },
    async initFollowLog() {
      const res = await followLog(this.cardId,);
      this.followLog = res.rows;
    },
    handleMore() {
      const { isMore, noteList, } = this;
      if (!isMore) {
        this.showNoteList = noteList;
      } else {
        this.showNoteList = [noteList[0],
        ];
      }
      this.isMore = !isMore;
    },
    //  查询静默类型列表
    async getInvalidTypesList(type,) {
      // type：1-静默、2-联系未果
      const res = await getInvalidTypeOpts({ type, },);
      const temp = res.content.map((item,) => {
        if (item.status) {
          return {
            id: item.id,
            name: item.name,
          };
        }
      },).filter((item,) => item,);
      if (type === 1) {
        this.invalidTypeOpt = temp;
      } else {
        this.contactNoResultOpt = temp;
      }
    },
    resetForm() {
      this.$refs.form.resetFields();
    },
    onSubmit() {
      const { $refs, editEvaluation, } = this;
      $refs.form.validate((valid,) => {
        if (valid) {
          editEvaluation();
        } else {
          Toast.fail('无法提交',);
          return false;
        }
      },);
    },
    async editEvaluation() {
      const { handleLoading, cardId, form, initFollowLog, } = this;
      handleLoading();
      const { evaluation, intentionLevel, invalidTypeId, remark, } = form;
      const data = {
        id: cardId,
        evaluation,
        intentionLevel,
        invalidTypeId,
        remark,
      };
      const res = await editEvaluation(data,).finally(() => handleLoading(),);
      if (typeof res === 'string') {
        this.$emit('refresh',);
        if (form.remark) {
          initFollowLog();
          form.remark = '';
        }
      }
    },
    handleLoading() {
      this.loading = !this.loading;
    },
    handleFormEvaluation() {
      const { form, } = this;
      form.invalidTypeId && (form.invalidTypeId = undefined);
      console.log(form.invalidTypeId,);
    },
  },
};
</script>

<style lang="less">
.follow-detail {
  padding: 15px;
	h3 {
		font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    color: #333333;
	}
	.card {
    padding: 15px;
    margin-bottom: 10px;
    background: #FFFFFF;
    border-radius: 6px;
    .title {
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
    }
		.ant-top {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      font-size: 12px;
      .ant-form-item {
        display: flex;
        align-items: center;
        &-label {
          padding-right: 6px;
          color: #333333;
        }
        .intention-level {
          width: 113px;
        }
        .invalid-type-id {
          width: 250px;
        }
        .ant-radio-button-wrapper {
          font-size: 12px;
          color: #999999;
        }
        .ant-radio-button-wrapper-checked {
          color: #FFFFFF;
          background-color: #577DF8;
          border-color: #577DF8;
        }
        .ant-form-explain {
          color: red;
        }
        .ant-select {
          font-size: 12px;
        }
      }
		}
    .ant-input {
      height: 113px;
      margin: 20px 0;
    }
    .btns {
      display: flex;
      justify-content: space-evenly;
      .ant-btn {
        width: 152px;
        height: 38px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
      }
      .ant-btn-primary {
        background-color: #577DF8;
        border-color: #577DF8;
        margin-left: 11px;
      }
    }
  }
	.more {
		display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 10px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    cursor: pointer;
		.text {
			padding-right: 6px;
		}
	}
}
.ant-select-dropdown-menu-item {
  font-size: 12px;
}
</style>
