import request from '..';

const BASE_URL = '/quickReplys';

/**
 * @description 获取快捷回复树
 */
export function getReplyTree() {
  return request({
    url: `${BASE_URL}/all`,
    method: 'GET',
  },);
}

/**
 * @description 快捷回复设为常用
 * @param  {number} id
 */
export function setAsCommon(id,) {
  return request({
    url: `${BASE_URL}/oftenUsed/${id}`,
    method: 'POST',
  },);
}

/**
 * @description 取消常用
 * @param  {number} id
 */
export function cancelCommon(id,) {
  return request({
    url: `${BASE_URL}/oftenUsed/${id}`,
    method: 'DELETE',
  },);
}
