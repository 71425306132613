// import JSEncrypt from 'jsencrypt';
// const CryptoJS = require('crypto-js',);
import { openDefaultBrowser, } from './wxWork';

const Utils = {
  getStore(key,) {
    let _value = sessionStorage.getItem(key,);
    _value = _value === null ? '' : _value;
    return _value;
  },
  setStore(key, value,) {
    if (key && value !== '') {
      if (typeof value === 'object') {
        value = JSON.stringify(value,);
      }
      sessionStorage.setItem(key, value,);
    }
  },
  removeStore(key,) {
    if (this.getStore(key,)) {
      sessionStorage.removeItem(key,);
    }
  },
  getLocalstorageStore(key,) {
    let _value = window.localStorage.getItem(key,);
    _value = _value === null ? '' : _value;
    return _value;
  },
  setLocalstorageStore(key, value,) {
    if (key && value !== '') {
      if (typeof value === 'object') {
        value = JSON.stringify(value,);
      }
      window.localStorage.setItem(key, value,);
    }
  },
  removeLocalstorageStore(key,) {
    if (this.getStore(key,)) {
      window.localStorage.removeItem(key,);
    }
  },
  getCookie(name,) {
    if (!document.cookie.length) return;
    const reg = new RegExp('(^| )' + name + '=([^;]*)(;|$)',);
    const arr = document.cookie.match(reg,);

    if (Array.isArray(arr,)) {
      return unescape(arr[2],);
    } else {
      return null;
    }
  },
  setCookie(name, value,) {
    // 登录token四小时过期
    const exp = new Date();
    exp.setTime(exp.getTime() + 4 * 60 * 60 * 1000,);
    document.cookie = name + '=' + escape(value,) + ';expires=' + exp.toGMTString();
  },
  delCookie(name,) {
    var exp = new Date();
    exp.setTime(exp.getTime() - 1,);
    var cval = this.getCookie(name,);
    if (cval !== (null || undefined)) { document.cookie = name + '=' + cval + ';expires=' + exp.toGMTString(); }
  },
  padStart(sourceString,) {
    if (typeof sourceString === 'undefined') return '';
    return (sourceString - 10) < 0 ? '0' + sourceString : sourceString;
  },
  // encryptByDES(message,) {
  //   if (!message) return;

  //   let key = '29582849';
  //   let iv = '98626432';
  //   key = CryptoJS.MD5(key,);
  //   iv = CryptoJS.enc.Utf8.parse(iv,);
  //   const encrypted = CryptoJS.DES.encrypt(message, key, {
  //     iv: iv,
  //     mode: CryptoJS.mode.CBC,
  //     padding: CryptoJS.pad.Pkcs7,
  //   },);
  //   return encrypted.toString();
  // },
  generateDateByString(str,) {
    let result = {};
    if (str && typeof str === 'string') {
      str = str.replace(/-/g, '/',); // 苹果safari, - 穿件日期NAN.
      result = new Date(str,);
    } else {
      result = new Date();
    }
    return result;
  },
  getQueryString(name,) {
    const str = `(^|&)${name}=([^&]*)(&|$)`;
    const reg = new RegExp(str, 'i',);
    const r = window.location.search.substr(1,).match(reg,);
    if (r != null) return decodeURI(r[2],);
    return null;
  },
  setBodyScroll(flag,) {
    const body = document.getElementsByTagName('body',)[0];
    if (flag) {
      body.style.position = 'fixed';
    } else {
      body.style.position = 'initial';
    }
  },
  // getRsaRes(data,) {
  //   const publicKey = 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCLnogulz4NHwG7aLTtDVbCF/sCAn4PojnJdgud4WZSEPi5LOAG6JFpxcyWYT+DvQENn3sk9yIX/dS7Jf/NYX00G6aCKmPR+1ExMqWJizrPFdkNSMpUpzn9HNYPDbzfOru4PWU2DeI5EvH0ZNqOescZ4Q19haPZnhJTLrEhAF24+wIDAQAB';
  //   const encrypt = new JSEncrypt();
  //   encrypt.setPublicKey(publicKey,);// publicKey为公钥
  //   return encrypt.encrypt(data,);
  // },
  toCrm(pageUrl, params,) {
    const crmUrl = process.env.VUE_APP_CRM_URL;
    const token = encodeURI(Utils.getCookie('Authorization',),);
    openDefaultBrowser(`${crmUrl}${pageUrl}?token=${token}&${params}`,);
  },
};

export default Utils;
