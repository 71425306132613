<template>
  <div class="quick-file">
    <a-table
      bordered
      :columns="columns"
      :data-source="!breadCrumb ? parent : parent[clickRowIdx].children"
      :pagination="false"
      :custom-row="handleRow"
      children-column-name="son"
    >
      <template #title>
        <a-bread-crumb
          separator=">"
        >
          <a-bread-crumb-item>
            <span
              class="return-parent"
              @click="returnParent"
            >
              全部
            </span>
          </a-bread-crumb-item>
          <a-bread-crumb-item>{{ breadCrumb }}</a-bread-crumb-item>
        </a-bread-crumb>
      </template>
    </a-table>
    <div class="btn">
      <van-button
        round
        plain
        hairline
        size="small"
        type="info"
        @click="toCrm"
      >
        文件管理
      </van-button>
    </div>
  </div>
</template>

<script>
import ATable from 'ant-design-vue/lib/table';
import ABreadCrumb from 'ant-design-vue/lib/breadcrumb';
import ABreadCrumbItem from 'ant-design-vue/lib/breadcrumb/BreadcrumbItem';
import 'ant-design-vue/lib/table/style';
import 'ant-design-vue/lib/breadcrumb/style';
import { getFileList, } from '@/request/api/quickFile';
import { Button, } from 'vant';
import Utils from '@/script/utils';
import { quickFile, } from '@/script/wxWork';

export default {
  name: 'QuickFile',
  components: {
    ATable,
    ABreadCrumb,
    ABreadCrumbItem,
    [Button.name]: Button,
  },
  data() {
    return {
      columns: [
        {
          title: '文档',
          dataIndex: 'name',
          align: 'center',
        },
        {
          title: '编辑',
          dataIndex: 'updatedBy',
          align: 'center',
        },
        {
          title: '更新时间',
          dataIndex: 'updateTime',
          align: 'center',
        },
      ],
      parent: [],
      breadCrumb: '',
      clickRowIdx: null,
    };
  },
  created() {
    getFileList().then((res,) => {
      this.formatParent(res.quickFileAllList,);
    },);
  },
  methods: {
    formatParent(data,) {
      const { formatChildren, } = this;
      this.parent = data.map((parent, idx,) => ({
        key: parent.id || idx,
        name: parent.name,
        updatedBy: parent.updatedBy,
        updateTime: parent.updateTime,
        children: formatChildren(parent.children,),
      }),);
    },
    formatChildren(children,) {
      return children.map((item,) => ({
        key: item.id,
        name: item.name,
        updatedBy: item.updatedBy,
        updateTime: item.updateTime,
        selectable: item.children && !item.children.length,
        mediaId: item.mediaId,
        children: item.children ? this.formatChildren(item.children,) : [],
      }),);
    },
    handleRow(row, idx,) {
      const { name, children, mediaId, } = row;
      return {
        on: {
          // 点击行
          click: (event,) => {
            if (children.length) {
              this.breadCrumb = name;
              this.clickRowIdx = idx;
            }
          },
          dblclick: (event,) => {
            // 双击到输入框
            quickFile(mediaId,).then();
          },
        },
      };
    },
    returnParent() {
      this.breadCrumb = '';
    },
    toCrm() {
      Utils.toCrm('wxWork/quickFile',);
    },
  },
};
</script>

<style lang="less">
.quick-file {
  .return-parent {
    cursor: pointer;
  }
  .btn {
    text-align: center;
    padding: 20px 0;
  }
}
</style>
