<template>
  <div class="quick-reply">
    <tree
      :tree-data="commonlyUsed"
      :default-expand-all="true"
      @select="(selectedKey) => handleSelect(selectedKey, true)"
      @rightClick="quickReply"
    />
    <tree
      :tree-data="all"
      :default-expand-all="true"
      @select="(selectedKey) => handleSelect(selectedKey, false)"
      @rightClick="quickReply"
    />
    <div
      class="btn-group"
    >
      <van-button
        round
        size="small"
        color="#577DF8"
        @click="handleCommon"
      >
        {{ isCancelCommon ? '取消常用' : '设为常用' }}
      </van-button>
      <van-button
        round
        plain
        hairline
        size="small"
        type="info"
        @click="toCrm"
      >
        +新增快捷语
      </van-button>
    </div>
  </div>
</template>

<script>

import Tree from 'ant-design-vue/lib/tree';
import 'ant-design-vue/lib/tree/style';
import { Button, Toast, } from 'vant';
import Utils from '@/script/utils';
import { getReplyTree, setAsCommon, cancelCommon, } from '@/request/api/quickReply';
import { quickReply, } from '@/script/wxWork';

export default {
  name: 'QuickReply',
  components: {
    Tree,
    [Button.name]: Button,
  },
  data() {
    return {
      commonlyUsed: [],
      all: [{
        title: '全部',
        key: 0,
        selectable: false,
        isCategory: true,
        children: [{
          title: '通用快捷语',
          key: 1,
          selectable: false,
          isCategory: true,
          children: [],
        },
        {
          title: '个人快捷语',
          key: 2,
          selectable: false,
          isCategory: true,
          children: [],
        },
        ],
      },
      ],
      setCommonId: null,
      isCancelCommon: false,
    };
  },
  created() {
    this.getReplyTree();
  },
  methods: {
    getReplyTree() {
      return new Promise((resolve,) => {
        getReplyTree().then((res,) => {
          if (res) {
            const { format, } = this;
            const { oftenUsedList, generalList, personalList, } = res;
            format(oftenUsedList, 0,);
            format(generalList, 1,);
            format(personalList, 2,);
            resolve(null,);
          }
        },);
      },);
    },
    formatChildren(children,) {
      return children.map((item,) => ({
        title: item.name || item.content,
        key: item.id,
        selectable: item.children && !item.children.length,
        children: item.children ? this.formatChildren(item.children,) : [],
        isCategory: false,
      }),);
    },
    format(data, type,) {
      const { formatChildren, } = this;
      const formatData = data.map((item, index,) => ({
        title: item.name,
        key: item.id || index,
        selectable: !item.children.length,
        children: formatChildren(item.children,),
        isCategory: true,
      }),);
      switch (type) {
        case 0:
          this.commonlyUsed = formatData;
          break;
        case 1:
          this.all[0].children[0].children = formatData;
          break;
        case 2:
          this.all[0].children[1].children = formatData;
          break;
        default:
          break;
      }
    },
    toCrm() {
      Utils.toCrm('wxWork/quickReply', 'level=1',);
    },
    handleSelect(selectedKeys, isCancelCommon,) {
      this.setCommonId = selectedKeys[0];
      this.isCancelCommon = isCancelCommon;
    },
    handleCommon() {
      const { isCancelCommon, setCommonId, getReplyTree, commonlyUsed, } = this;
      if (setCommonId) {
        const Is_CommonlyUsed = commonlyUsed[0].children.find((item,) => item.key === setCommonId,);
        if (!isCancelCommon) {
          if (Is_CommonlyUsed) {
            Toast.fail('此回复语已设置为常用啦~',);
          } else {
            setAsCommon(setCommonId,).then(() => {
              getReplyTree().then(() => {
                Toast.success('设置成功',);
              },);
            },);
          }
        } else {
          cancelCommon(setCommonId,).then(() => {
            getReplyTree().then(() => {
              Toast.success('取消成功',);
            },);
          },);
        }
      } else {
        Toast.fail('请先选择一条快捷回复~',);
      }
    },
    quickReply({ node, },) {
      const { dataRef, title, } = node;
      if (!dataRef.isCategory) {
        quickReply(title,).then();
      }
    },
  },
};
</script>

<style lang="less">
.quick-reply {
  .btn-group {
    text-align: center;
    padding: 20px 0;
    .van-button--info {
      margin-left: 20px;
    }
  }
}
</style>
