var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"voice-player-com"},[_c('div',{staticStyle:{"display":"none"}},[_c('audio',{ref:"audio",attrs:{"controls":"controls"}},[_c('source',{attrs:{"src":_vm.url || '',"type":"audio/mp3"}})])]),_c('div',{class:[
      'voice-bg',
      {
        'voice-bg-right': _vm.isRight,
      }],on:{"click":_vm.handlePlay}},[(_vm.isPlaying)?_c('img',{class:{
        'is-right': _vm.isRight,
      },attrs:{"src":require("@/assets/images/voicePlayerCom/icon-player.gif"),"alt":"icon-player.gif"}}):_c('img',{class:{
        'is-right': _vm.isRight,
      },attrs:{"src":require("@/assets/images/voicePlayerCom/icon-player.png"),"alt":"icon-player.png"}}),_c('span',[_vm._v(_vm._s(_vm.time || 0)+"\"")])])])}
var staticRenderFns = []

export { render, staticRenderFns }