<template>
  <div class="note-com">
    <div class="content">{{ noteInfo.detail }}</div>
    <div class="bottom">
      <div class="operator">操作人：{{ noteInfo.createBy || noteInfo.updatedBy }}</div>
      <div class="time">{{ noteInfo.updateTime }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NoteCom',
  props: {
    noteInfo: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="less">
.note-com {
  padding-top: 15px;
  .content {
    padding-bottom: 5px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
  }
  .bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
  }
}
</style>
